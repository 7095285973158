<template>
  <div>
    <div class="form-group required">
      <input
        type="text"
        name="firstname"
        class="form-control required"
        placeholder="National Tax Reference Number"
        v-model="nationalTaxReferenceNumber"
      />
    </div>

    <div class="form-group required">
      <div class="styled-select clearfix">
        <select
          class="form-control required"
          name="country"
          v-model="nationalTaxReferenceNumberCountryCode"
        >
          <option :value="null">Issuing Country</option>
          <option
            v-for="country of countries"
            :value="country.code"
            :key="country.code"
          >
            {{ country.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group required add_top_30">
      <label
        >Please upload Tax certificate as provided by the Tax Department in the
        country of establishment<br /><small
          >(Files accepted: .pdf - Max file size: 5MB)</small
        ></label
      >

      <div class="fileupload">
        <a-upload
          name="data_file"
          :multiple="true"
          :action="fileUploadApiUrl"
          @change="(info) => handleFileChange(info)"
          :data="fileUploadParams()"
          list-type="text"
        >
          <button
            style="border-width: 2px"
            class="btn btn-flat border-warning bg-default btn-block"
            type="button"
          >
            {{
              nationalTaxReferenceDocument == null
                ? "Click to Upload"
                : "Click to replace uploaded file"
            }}
          </button>
        </a-upload>
        <div v-if="nationalTaxReferenceDocument != null">
          {{ nationalTaxReferenceDocument.fileName }}
        </div>
      </div>
    </div>

    <div class="form-group">
      <h6 class="mt-5">
        Do you have another establishment in the EU?
        <!-- <a><font-awesome-icon class="text-primary" icon="question-circle" /></a> -->
      </h6>

      <div class="btn-group block" role="group">
        <a
          @click="enableOtherRegistrations"
          class="btn btn-sm border-warning"
          :class="{
            'btn-warning': hasOtherEuEstablishments,
          }"
          size="small"
          type="primary"
        >
          {{ "Yes" }}
        </a>

        <a
          @click="() => (hasOtherEuEstablishments = false)"
          class="btn btn-sm border-warning"
          size="small"
          type="primary"
          :class="{
            'btn-warning': hasOtherEuEstablishments === false,
          }"
        >
          {{ "No" }}
        </a>
      </div>
    </div>

    <div class="" v-if="hasOtherEuEstablishments">
      <p>Please list all your existing registrations</p>

      <div>
        <div
          v-for="(registration, index) in otherEuEstablishments"
          :key="index"
        >
          <div class="row">
            <div class="col-6">
              <div class="form-group required">
                <div class="styled-select clearfix">
                  <select
                    class="form-control required"
                    name="country"
                    @change="
                      (event) =>
                        setRegistrationProp(
                          index,
                          'countryCode',
                          event.target.value
                        )
                    "
                  >
                    <option :value="null" key="-1">
                      Country of registration
                    </option>
                    <option
                      v-for="country of countries"
                      :selected="country.code == registration.countryCode"
                      :value="country.code"
                      :key="country.code"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="form-group required">
                <input
                  type="text"
                  name="firstname"
                  class="form-control required"
                  placeholder="VAT-ID number"
                  :value="registration.vatNumber"
                  @change="
                    (event) =>
                      setRegistrationProp(
                        index,
                        'vatNumber',
                        event.target.value
                      )
                  "
                />
              </div>
            </div>
          </div>

          <div class="form-group required">
            <input
              type="text"
              name="firstname"
              class="form-control required"
              placeholder="Trade name"
              :value="registration.tradingNames"
              @change="
                (event) =>
                  setRegistrationProp(index, 'tradingNames', event.target.value)
              "
            />
          </div>

          <div class="form-group required">
            <input
              type="text"
              name="firstname"
              class="form-control required"
              placeholder="Street"
              :value="registration.addressStreet"
              @change="
                (event) =>
                  setRegistrationProp(
                    index,
                    'addressStreet',
                    event.target.value
                  )
              "
            />
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group required">
                <input
                  type="text"
                  name="firstname"
                  class="form-control required"
                  placeholder="House number"
                  :value="registration.addressHouseNumber"
                  @change="
                    (event) =>
                      setRegistrationProp(
                        index,
                        'addressHouseNumber',
                        event.target.value
                      )
                  "
                />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <input
                  type="text"
                  name="firstname"
                  class="form-control required"
                  placeholder="Additional info"
                  :value="registration.addressOther"
                  @change="
                    (event) =>
                      setRegistrationProp(
                        index,
                        'addressOther',
                        event.target.value
                      )
                  "
                />
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group required">
                <input
                  type="text"
                  name="firstname"
                  class="form-control required"
                  placeholder="Postal code"
                  :value="registration.addressPostalCode"
                  @change="
                    (event) =>
                      setRegistrationProp(
                        index,
                        'addressPostalCode',
                        event.target.value
                      )
                  "
                />
              </div>
            </div>
            <div class="col-6">
              <div class="form-group required">
                <input
                  type="text"
                  name="firstname"
                  class="form-control required"
                  placeholder="City"
                  :value="registration.addressCity"
                  @change="
                    (event) =>
                      setRegistrationProp(
                        index,
                        'addressCity',
                        event.target.value
                      )
                  "
                />
              </div>
            </div>
          </div>

          <div class="form-group required">
            <small
              >Please upload Tax certificate as provided by the Tax Department
              in the country of registration<br /><small
                >(Files accepted: .pdf - Max file size: 5MB)</small
              ></small
            >

            <div class="fileupload">
              <a-upload
                name="data_file"
                :multiple="true"
                :action="fileUploadApiUrl"
                @change="(info) => handleRowFileChange(index, info)"
                :data="fileUploadParams()"
                list-type="text"
              >
                <button
                  style="border-width: 2px"
                  class="btn btn-flat border-warning bg-default btn-block"
                  type="button"
                >
                  {{
                    registration.document == null
                      ? "Click to Upload"
                      : "Click to replace uploaded file"
                  }}
                </button>
              </a-upload>
              <div v-if="registration.document != null">
                {{ registration.document.fileName }}
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12 text-right">
              <a
                @click="removeRegistration(index)"
                class="btn btn-light btn-sm"
                size="small"
                type="primary"
              >
                <font-awesome-icon icon="trash-alt" class="text-danger" />
                {{ "Remove this registration" }}
              </a>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <a
              @click="addRegistration"
              class="btn btn-light btn-sm"
              size="small"
              type="primary"
            >
              <font-awesome-icon icon="plus-circle" class="text-primary" />
              {{ "Add more registrations" }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <h6 class="mt-5">
        Do you have indirect sales via an electronic interfaces?
        <!-- <a><font-awesome-icon class="text-primary" icon="question-circle" /></a> -->
      </h6>

      <div class="btn-group block" role="group">
        <a
          @click="() => (hasIndirectSales = true)"
          class="btn btn-sm border-warning"
          :class="{
            'btn-warning': hasIndirectSales,
          }"
          size="small"
          type="primary"
        >
          {{ "Yes" }}
        </a>

        <a
          @click="() => (hasIndirectSales = false)"
          class="btn btn-sm border-warning"
          size="small"
          type="primary"
          :class="{
            'btn-warning': hasIndirectSales === false,
          }"
        >
          {{ "No" }}
        </a>
      </div>
    </div>

    <div class="form-group" v-if="hasIndirectSales">
      <div class="">
        <div class="form-group terms">
          <label class="container_check">
            Direct sales from your own online webshop
            <input
              type="checkbox"
              name="terms"
              value="Yes"
              class="required"
              v-model="hasDirectSalesViaOwnShop"
            />
            <span class="checkmark"></span>
          </label>
        </div>

        <div class="form-group terms">
          <label class="container_check">
            Indirect sales through other marketplaces
            <input
              type="checkbox"
              name="terms"
              value="Yes"
              class="required"
              :checked="hasIndirectSalesViaOtherMarketplaces"
              @change="(e) => changeOtherMarketplaces(e)"
            />
            <span class="checkmark"></span>
          </label>
        </div>

        <div class="" v-if="hasIndirectSalesViaOtherMarketplaces">
          <p>Please list all your indirect selling marketplaces</p>

          <div>
            <div
              v-for="(marketplace, index) in indirectSalesInterfaces"
              :key="index"
            >
              <div class="row">
                <div class="col-6">
                  <div class="form-group required">
                    <input
                      type="text"
                      name="firstname"
                      class="form-control required"
                      placeholder="VAT-ID number"
                      :value="marketplace.vatNumber"
                      @change="
                        (event) =>
                          setIndirectSellingProp(
                            index,
                            'vatNumber',
                            event.target.value
                          )
                      "
                    />
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group required">
                    <input
                      type="text"
                      name="firstname"
                      class="form-control required"
                      placeholder="Marketplace name"
                      :value="marketplace.marketplaceName"
                      @change="
                        (event) =>
                          setIndirectSellingProp(
                            index,
                            'marketplaceName',
                            event.target.value
                          )
                      "
                    />
                  </div>
                </div>
              </div>

              <div class="form-group">
                <input
                  type="text"
                  name="firstname"
                  class="form-control required"
                  placeholder="Marketplace iOSS number if provided"
                  :value="marketplace.iossNumber"
                  @change="
                    (event) =>
                      setIndirectSellingProp(
                        index,
                        'iossNumber',
                        event.target.value
                      )
                  "
                />
              </div>

              <div class="form-group">
                <span class="mt-5">
                  Does this iOSS applies to all your sales?
                </span>

                <div class="btn-group block" role="group">
                  <a
                    @click="
                      () =>
                        setIndirectSellingProp(
                          index,
                          'applicableToAllSales',
                          true
                        )
                    "
                    class="btn btn-sm border-warning"
                    :class="{
                      'btn-warning': marketplace.applicableToAllSales,
                    }"
                    size="small"
                    type="primary"
                  >
                    {{ "Yes" }}
                  </a>

                  <a
                    @click="
                      () =>
                        setIndirectSellingProp(
                          index,
                          'applicableToAllSales',
                          false
                        )
                    "
                    class="btn btn-sm border-warning"
                    size="small"
                    type="primary"
                    :class="{
                      'btn-warning': marketplace.applicableToAllSales === false,
                    }"
                  >
                    {{ "No" }}
                  </a>
                </div>
              </div>

              <div
                class="form-group"
                v-show="marketplace.applicableToAllSales === false"
              >
                <input
                  type="text"
                  name="firstname"
                  class="form-control required"
                  placeholder="When does it aplies what is the condition set?"
                  :value="marketplace.whenNotApplicable"
                  @change="
                    (event) =>
                      setIndirectSellingProp(
                        index,
                        'whenNotApplicable',
                        event.target.value
                      )
                  "
                />
              </div>

              <div class="row mb-3">
                <div class="col-12 text-right">
                  <a
                    @click="removeMarketplace(index)"
                    class="btn btn-light btn-sm"
                    size="small"
                    type="primary"
                  >
                    <font-awesome-icon icon="trash-alt" class="text-danger" />
                    {{ "Remove this marketplace" }}
                  </a>
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <a
                  @click="addMarketplace"
                  class="btn btn-light btn-sm"
                  size="small"
                  type="primary"
                >
                  <font-awesome-icon icon="plus-circle" class="text-primary" />
                  {{ "Add more marketplaces" }}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { createComputed } from "@/services/utilities";
import { API_URL } from "@/services/config";

import { Select, Upload, Button, Icon } from "ant-design-vue";
Vue.use(Select);
Vue.use(Button);
Vue.use(Icon);
Vue.use(Upload);

export default {
  name: "Step4",
  data: function () {
    return {
      fileUploadApiUrl: API_URL + "wapi/ioss/account/document",
      uploadedFileList: [],
    };
  },
  methods: {
    fileUploadParams() {
      return {
        token: this.token,
      };
    },

    handleFileChange(info) {
      if (info.file.status !== "uploading") {
        this.uploadedFileList = info.fileList;
      }
      if (
        info.file.status === "done" &&
        info.file.response.status === "success"
      ) {
        this.nationalTaxReferenceDocument = info.file.response.data.file;
        this.uploadedFileList = [];
      }
    },
    handleRowFileChange(index, info) {
      if (
        info.file.status === "done" &&
        info.file.response.status === "success"
      ) {
        this.setRegistrationProp(
          index,
          "document",
          info.file.response.data.file
        );
      }
    },
    setRegistrationProp(index, objectProp, data) {
      this.$store.dispatch("form/setFormArrayObjectProperty", {
        prop: "otherEuEstablishments",
        index,
        objectProp,
        data,
      });
    },
    enableOtherRegistrations() {
      this.hasOtherEuEstablishments = true;
      if (!this.otherEuEstablishments.length) {
        this.addRegistration();
      }
    },
    addRegistration() {
      this.$store.dispatch("form/addFormArrayProperty", {
        prop: "otherEuEstablishments",
        data: {
          countryCode: null,
          vatNumber: "",
          document: null,
          tradingNames: "",
          addressStreet: "",
          addressHouseNumber: "",
          addressOther: "",
          addressPostalCode: "",
          addressCity: "",
        },
      });
    },
    removeRegistration(index) {
      console.log("At index", index);
      this.$store.dispatch("form/removeFormArrayProperty", {
        prop: "otherEuEstablishments",
        index,
      });
    },
    changeOtherMarketplaces(event) {
      this.hasIndirectSalesViaOtherMarketplaces = event.target.checked;
      if (!this.indirectSalesInterfaces.length) {
        this.addMarketplace();
      }
    },

    removeMarketplace(index) {
      this.$store.dispatch("form/removeFormArrayProperty", {
        prop: "indirectSalesInterfaces",
        index,
      });
    },
    addMarketplace() {
      this.$store.dispatch("form/addFormArrayProperty", {
        prop: "indirectSalesInterfaces",
        data: {
          vatNumber: "",
          marketplaceName: "",
          iossNumber: "",
          applicableToAllSales: "",
          whenNotApplicable: "",
        },
      });
    },
    setIndirectSellingProp(index, objectProp, data) {
      this.$store.dispatch("form/setFormArrayObjectProperty", {
        prop: "indirectSalesInterfaces",
        index,
        objectProp,
        data,
      });
    },
  },
  computed: {
    ...mapGetters("system", ["countries"]),
    ...mapGetters("form", ["token"]),
    hasOtherEuEstablishments: createComputed(
      "form",
      "form.hasOtherEuEstablishments"
    ),
    otherEuEstablishments: createComputed("form", "form.otherEuEstablishments"),
    nationalTaxReferenceNumber: createComputed(
      "form",
      "form.nationalTaxReferenceNumber"
    ),
    nationalTaxReferenceDocument: createComputed(
      "form",
      "form.nationalTaxReferenceDocument"
    ),
    nationalTaxReferenceNumberCountryCode: createComputed(
      "form",
      "form.nationalTaxReferenceNumberCountryCode"
    ),
    hasIndirectSales: createComputed("form", "form.hasIndirectSales"),

    hasDirectSalesViaOwnShop: createComputed(
      "form",
      "form.hasDirectSalesViaOwnShop"
    ),
    hasIndirectSalesViaOtherMarketplaces: createComputed(
      "form",
      "form.hasIndirectSalesViaOtherMarketplaces"
    ),
    indirectSalesInterfaces: createComputed(
      "form",
      "form.indirectSalesInterfaces"
    ),
  },
};
</script>


<style>
.btn-group.block {
  display: flex;
}

.block .btn {
  flex: 1;
}
.ant-upload {
  width: 100%;
}
</style>
