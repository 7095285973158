<template>
  <div class="about">
    <DecryptionForm/>
  </div>
</template>

<script>

import DecryptionForm from '@/components/DecryptionForm.vue'

export default {
  name: "Register",
  components: {
    DecryptionForm
  }
}
</script>
