<template>
  <div>
    <div class="form-group required">
      <input
        type="text"
        name="firstname"
        class="form-control required"
        placeholder="Company Website"
        v-model="companyWebsite"
      />
    </div>

    <div class="form-group required">
      <input
        type="text"
        name="firstname"
        class="form-control required"
        placeholder="Company Email Address"
        v-model="companyEmail"
      />
    </div>


    <div class="form-group required">
      <h6 class="mt-5">
        Short description of your activities
        <a-popover title="">
          <template slot="content">
            <div>
              Please describe your sales activities. Do you sell goods and/or services, which kind of goods, etc.
            </div>
          </template>
          <a
            ><font-awesome-icon class="text-primary" icon="question-circle"
          /></a>
        </a-popover>
      </h6>

      <textarea
        type="text"
        name="firstname"
        class="form-control required"
        placeholder="Please provide a short comprehensive info about your activities"
        v-model="companySalesActivities"
      />
    </div>


    <div class="form-group">
      <h6 class="mt-5">
        Do you operate any e-shop website different than your main company
        website?
      </h6>

      <div class="btn-group block" role="group">
        <a
          @click="enableEshopWebsites"
          class="btn btn-sm border-warning"
          :class="{
            'btn-warning': hasMultipleEshopWebsites,
          }"
          size="small"
          type="primary"
        >
          {{ "Yes" }}
        </a>

        <a
          @click="() => (hasMultipleEshopWebsites = false)"
          class="btn btn-sm border-warning"
          size="small"
          type="primary"
          :class="{
            'btn-warning': hasMultipleEshopWebsites === false,
          }"
        >
          {{ "No" }}
        </a>
      </div>
    </div>

    <div class="form-group">
      <div v-if="hasMultipleEshopWebsites">
        <div
          class="input-group required mb-3"
          v-for="(name, index) in eshopWebsites"
          :key="index"
        >
          <input
            type="text"
            name="firstname"
            class="form-control required"
            placeholder="Eshop website"
            @change="({ target }) => setEshopWebsite(index, target.value)"
            :value="name"
          />

          <div class="input-group-append">
            <button
              @click="removeEshopWebsite(index)"
              class="btn btn-outline-secondary"
              type="button"
            >
              <font-awesome-icon icon="trash-alt" class="text-danger" />
            </button>
          </div>
        </div>
        <div>
          <a
            class="btn btn-light btn-sm"
            size="small"
            type="primary"
            @click="addEshopWebsite"
          >
            <font-awesome-icon icon="plus-circle" class="text-primary" />
            {{ "Add more eshop websites" }}
          </a>
        </div>
      </div>
    </div>

    <div class="form-group">
      <h6 class="mt-5">
        Do you operate any trading name different than you company Legal Name in
        your home country?
        <!-- <a><font-awesome-icon class="text-primary" icon="question-circle" /></a> -->
      </h6>

      <div class="btn-group block" role="group">
        <a
          @click="enableTradeName"
          class="btn btn-sm border-warning"
          :class="{
            'btn-warning': hasMultipleTradingNames,
          }"
          size="small"
          type="primary"
        >
          {{ "Yes" }}
        </a>

        <a
          @click="() => (hasMultipleTradingNames = false)"
          class="btn btn-sm border-warning"
          size="small"
          type="primary"
          :class="{
            'btn-warning': hasMultipleTradingNames === false,
          }"
        >
          {{ "No" }}
        </a>
      </div>
    </div>

    <div v-if="hasMultipleTradingNames">
      <div
        class="input-group required mb-3"
        v-for="(name, index) in tradingNames"
        :key="index"
      >
        <input
          type="text"
          name="firstname"
          class="form-control required"
          placeholder="Trading name"
          @change="({ target }) => setTradingName(index, target.value)"
          :value="name"
        />

        <div class="input-group-append">
          <button
            @click="removeTradingName(index)"
            class="btn btn-outline-secondary"
            type="button"
          >
            <font-awesome-icon icon="trash-alt" class="text-danger" />
          </button>
        </div>
      </div>

      <div>
        <a
          class="btn btn-light btn-sm"
          size="small"
          type="primary"
          @click="addTradingName"
        >
          <font-awesome-icon icon="plus-circle" class="text-primary" />
          {{ "Add more trading names" }}
        </a>
      </div>
    </div>

  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import { createComputed } from "@/services/utilities";

export default {
  name: "Step3",
  data: function () {
    return {
      differentCorrespondenceAddress: null,
    };
  },
  methods: {
    enableTradeName() {
      this.hasMultipleTradingNames = true;
      if (!this.tradingNames.length) {
        this.addTradingName();
      }
    },
    enableEshopWebsites() {
      this.hasMultipleEshopWebsites = true;
      if (!this.eshopWebsites.length) {
        this.addEshopWebsite();
      }
    },
    setTradingName(index, data) {
      this.$store.dispatch("form/setFormArrayProperty", { prop: 'tradingNames', index, data });
    },
    addTradingName() {
      this.$store.dispatch("form/addFormArrayProperty", { prop: 'tradingNames', data: "" });
    },
    removeTradingName(index) {
      this.$store.dispatch("form/removeFormArrayProperty", { prop: 'tradingNames', index });
    },
    setEshopWebsite(index, data) {
      this.$store.dispatch("form/setFormArrayProperty", { prop: 'eshopWebsites', index, data });
    },
    addEshopWebsite() {
      this.$store.dispatch("form/addFormArrayProperty", { prop: 'eshopWebsites', data: "" });
    },
    removeEshopWebsite(index) {
      this.$store.dispatch("form/removeFormArrayProperty", { prop: 'eshopWebsites', index });
    },
  },
  computed: {
    ...mapGetters("system", ["countries"]),
    ...mapState("form", {
      eshopWebsites: (state) => state.form.eshopWebsites,
      tradingNames: (state) => state.form.tradingNames,
    }),
    companyWebsite: createComputed("form", "form.companyWebsite"),
    hasMultipleEshopWebsites: createComputed(
      "form",
      "form.hasMultipleEshopWebsites"
    ),
    hasMultipleTradingNames: createComputed(
      "form",
      "form.hasMultipleTradingNames"
    ),
    companyEmail: createComputed(
      "form",
      "form.companyEmail"
    ),
    companySalesActivities: createComputed(
      "form",
      "form.companySalesActivities"
    ),
    
  },
};
</script>

<style scoped>
.btn-group.block {
  display: flex;
}

.block .btn {
  flex: 1;
}
</style>
