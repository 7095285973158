<template>
  <div>
    <div class="form-group required">
      <textarea
        type="text"
        name="firstname"
        class="form-control required"
        placeholder="Place(s) of dispatch of your goods to the EU"
        v-model="placesOfDispatch"
      />
    </div>

    <div class="form-group required">
      <textarea
        type="text"
        name="firstname"
        class="form-control required"
        placeholder="Place(s) of destination; ie: member states where you have customers?"
        v-model="placesOfDestination"
      />
    </div>

    <div class="form-group required">
      <input
        type="text"
        name="firstname"
        class="form-control required"
        placeholder="Who is or are your logistical service providers?"
        v-model="logisticsProviders"
      />
    </div>

    <div class="form-group required">
      <input
        type="text"
        name="firstname"
        class="form-control required"
        placeholder="Who is or are your customs broker?"
        v-model="customsBroker"
      />
    </div>

    <h6 class="mt-5">
      Sales forecast?

      <!-- <a-popover title="">
          <template slot="content">
            <div>
              We need your best estimates so we can provide you with a service level that is suitable for your needs.
            </div>
          </template>
          <a
            ><font-awesome-icon class="text-primary" icon="question-circle"
          /></a>
        </a-popover> -->
    </h6>

    <div class="form-group required">
      <input
        type="text"
        name="firstname"
        class="form-control required"
        placeholder="Number of consigments per month (estimated)"
        v-model="consigmentsPerMonth"
      />
    </div>

    <div class="form-group required">
      <div class="input-group">
        <input
          type="text"
          name="firstname"
          class="form-control required"
          placeholder="(Total) Value of goods sold per month"
          v-model="goodsSoldPerMonth"
        />
        <div class="input-group-append">
          <span class="input-group-text" id="">EUR</span>
        </div>
      </div>
    </div>

    <div class="form-group required">
      <input
        type="text"
        name="firstname"
        class="form-control required"
        placeholder="Country(ies) of entry in EU (not importation)"
        v-model="countriesOfEntry"
      />
    </div>
  </div>
</template>

<script>
// import { mapGetters } from "vuex";
import { createComputed } from "@/services/utilities";

export default {
  name: "Step6",
  computed: {
    placesOfDispatch: createComputed("form", "form.placesOfDispatch"),
    placesOfDestination: createComputed("form", "form.placesOfDestination"),
    logisticsProviders: createComputed("form", "form.logisticsProviders"),
    customsBroker: createComputed("form", "form.customsBroker"),
    consigmentsPerMonth: createComputed("form", "form.consigmentsPerMonth"),
    goodsSoldPerMonth: createComputed("form", "form.goodsSoldPerMonth"),
    countriesOfEntry: createComputed("form", "form.countriesOfEntry"),
  },
};
</script>
