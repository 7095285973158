<template>
  <div>
    <div class="form-group required">
      <input
        type="text"
        name="firstname"
        class="form-control required"
        placeholder="Representative salutation (e.g. Mr, Ms, Dr etc)"
        v-model="representativeSalutation"
      />
    </div>

    <div class="form-group required">
      <input
        type="text"
        name="firstname"
        class="form-control required"
        placeholder="Representative first name"
        v-model="representativeFirstName"
      />
    </div>

    <div class="form-group required">
      <input
        type="text"
        name="firstname"
        class="form-control required"
        placeholder="Representative last name"
        v-model="representativeLastName"
      />
    </div>

    <div class="form-group required">
      <input
        type="text"
        name="firstname"
        class="form-control required"
        placeholder="Capacity/Job title"
        v-model="representativeJobTitle"
      />
    </div>

    <p>
      Please upload the Passport or ID Card of the legal representative of the
      company. This is required to verify the legitimacy of the representative.
    </p>

    <div class="btn-group block" role="group">
      <a
        @click="() => (isPassport = true)"
        class="btn btn-sm border-warning"
        :class="{
          'btn-warning': isPassport,
        }"
        size="small"
        type="primary"
      >
        {{ "Passport" }}
      </a>

      <a
        @click="() => (isPassport = false)"
        class="btn btn-sm border-warning"
        size="small"
        type="primary"
        :class="{
          'btn-warning': isPassport === false,
        }"
      >
        {{ "ID Card" }}
      </a>
    </div>

    <div class="form-group required add_top_30">
      <label
        >Please provide the {{ isPassport ? "Passport" : "ID card" }} front
        page<br /><small
          >(Files accepted: .pdf, .jpg, .png - Max file size: 5MB)</small
        ></label
      >
      <div class="fileupload">
        <a-upload
          name="data_file"
          :multiple="true"
          :action="fileUploadApiUrl"
          @change="
            (info) => handleFileChange('legalRepresentativeIdFrontPage', info)
          "
          :data="fileUploadParams()"
          list-type="text"
        >
          <button
            style="border-width: 2px"
            class="btn btn-flat border-warning bg-default btn-block"
            type="button"
          >
            {{
              legalRepresentativeIdFrontPage == null
                ? "Click to Upload"
                : "Click to replace uploaded file"
            }}
          </button>
        </a-upload>
        <div v-if="legalRepresentativeIdFrontPage != null">
          {{ legalRepresentativeIdFrontPage.fileName }}
        </div>
      </div>
    </div>

    <div class="form-group required add_top_30">
      <label
        >Please provide the {{ isPassport ? "Passport" : "ID card" }} back
        page<br /><small
          >(Files accepted: .pdf, .jpg, .png - Max file size: 5MB)</small
        ></label
      >
      <div class="fileupload">
        <a-upload
          name="data_file"
          :multiple="true"
          :action="fileUploadApiUrl"
          @change="
            (info) => handleFileChange('legalRepresentativeIdBackPage', info)
          "
          :data="fileUploadParams()"
          list-type="text"
        >
          <button
            style="border-width: 2px"
            class="btn btn-flat border-warning bg-default btn-block"
            type="button"
          >
            {{
              legalRepresentativeIdBackPage == null
                ? "Click to Upload"
                : "Click to replace uploaded file"
            }}
          </button>
        </a-upload>
        <div v-if="legalRepresentativeIdBackPage != null">
          {{ legalRepresentativeIdBackPage.fileName }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import { Select } from "ant-design-vue";
import { createComputed } from "@/services/utilities";
import { API_URL } from "@/services/config";

Vue.use(Select);

export default {
  name: "Step8",
  data: function () {
    return {
      fileUploadApiUrl: API_URL + "wapi/ioss/account/document",
    };
  },
  methods: {
    fileUploadParams() {
      return {
        token: this.token,
      };
    },

    handleFileChange(fileInput, info) {
      if (info.file.status !== "uploading") {
        this.uploadedFileList = info.fileList;
      }
      if (
        info.file.status === "done" &&
        info.file.response.status === "success"
      ) {
        console.log("Successful upload");
        this[fileInput] = info.file.response.data.file;
      }
    },
  },
  computed: {
    ...mapGetters("system", ["countries"]),
    ...mapGetters("form", ["token"]),
    legalRepresentativeIdFrontPage: createComputed(
      "form",
      "form.legalRepresentativeIdFrontPage"
    ),
    legalRepresentativeIdBackPage: createComputed(
      "form",
      "form.legalRepresentativeIdBackPage"
    ),
    representativeSalutation: createComputed(
      "form",
      "form.representativeSalutation"
    ),
    representativeFirstName: createComputed(
      "form",
      "form.representativeFirstName"
    ),
    representativeLastName: createComputed(
      "form",
      "form.representativeLastName"
    ),
    representativeJobTitle: createComputed(
      "form",
      "form.representativeJobTitle"
    ),
    isPassport: createComputed("form", "form.isPassport"),
  },
};
</script>


<style scoped>
.btn-group.block {
  display: flex;
}

.block .btn {
  flex: 1;
}
</style>
