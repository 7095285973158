<template>
  <div>
    <div class="form-group required">
      <input
        type="text"
        name="firstname"
        class="form-control required"
        placeholder="Name of the account holder"
        v-model="bankAccountHolderName"
      />
    </div>
    <div class="form-group required">
      <input
        type="text"
        name="firstname"
        class="form-control required"
        placeholder="Name of the bank"
        v-model="bankName"
      />
    </div>

    <div class="form-group required">
      <div class="styled-select clearfix">
        <select
          class="form-control required"
          name="country"
          v-model="bankCountryCode"
        >
          <option :value="null">Country of origin</option>
          <option
            v-for="country of countries"
            :value="country.code"
            :key="country.code"
          >
            {{ country.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group required">
      <input
        type="text"
        name="bic"
        class="form-control"
        placeholder="BIC / Swift Code"
        v-model="bankBicOrSwiftCode"
      />
    </div>
    <div class="form-group mb-0">
      <input
        type="email"
        name="email"
        class="form-control required"
        placeholder="IBAN (Your International Bank Account Number)"
        v-model="bankIban"
      />
    </div>
    <div class=""><span class="font-weight-light font-italic">or</span></div>
    <div class="form-group">
      <input
        type="email"
        name="email"
        class="form-control required"
        placeholder="Account Number"
        v-model="bankAccountNumber"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { createComputed } from "@/services/utilities";

export default {
  name: "Step7",
  computed: {
    ...mapGetters("system", ["countries"]),
    bankAccountHolderName: createComputed("form", "form.bankAccountHolderName"),
    bankCountryCode: createComputed("form", "form.bankCountryCode"),
    bankAccountNumber: createComputed("form", "form.bankAccountNumber"),
    bankIban: createComputed("form", "form.bankIban"),
    bankBicOrSwiftCode: createComputed("form", "form.bankBicOrSwiftCode"),
    bankName: createComputed("form", "form.bankName"),
  },
};
</script>
