<template>
  <div>
    <div class="form-group required">
      <input
        type="text"
        name="firstname"
        class="form-control required"
        placeholder="Company Legal Name"
        v-model="companyLegalName"
      />
    </div>

    <div class="form-group required">
      <div class="styled-select clearfix">
        <select
          class="form-control required"
          name="country"
          v-model="companyCorCode"
        >
          <option :value="null" disabled>
            Select country of establishment
          </option>
          <option
            v-for="country of countries"
            :value="country.code"
            :key="country.code"
          >
            {{ country.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group required">
      <p>
        Full name of your company incorporation type as defined by the law of
        your country (e.g. Limited Liability Company)
      </p>
      <input
        type="text"
        name="firstname"
        class="form-control required"
        placeholder="Incorporation type"
        v-model="incorporationType"
      />
    </div>

    <div class="form-group required">
      <input
        type="text"
        name="firstname"
        class="form-control required"
        placeholder="Incorporation registry type (e.g. Chamber of Commerce)"
        v-model="incorporationRegistryType"
      />
    </div>

    <div class="form-group required">
      <input
        type="text"
        name="firstname"
        class="form-control required"
        placeholder="Incorporation registry name"
        v-model="incorporationRegistryName"
      />
    </div>

    <div class="form-group required">
      <p>
        Business Registration in a register as required in your country, such as
        Trade Register, Company Register, Chamber of Commerce, and/or similar
        registers.
      </p>
      <input
        type="text"
        name="firstname"
        class="form-control required"
        placeholder="Registration number"
        v-model="chamberOfCommerceNumber"
      />
    </div>

    <div class="form-group required add_top_30">
      <label
        >Please upload Excerpt from the business registration document<br /><small
          >(Files accepted: .pdf - Max file size: 5MB)</small
        ></label
      >

      <div class="fileupload">
        <a-upload
          name="data_file"
          :multiple="true"
          :action="fileUploadApiUrl"
          @change="(info) => handleFileChange(info)"
          :data="fileUploadParams()"
          list-type="text"
        >
          <button
            style="border-width: 2px"
            class="btn btn-flat border-warning bg-default btn-block"
            type="button"
          >
            {{
              chamberOfCommerceDocument == null
                ? "Click to Upload"
                : "Click to replace uploaded file"
            }}
          </button>
        </a-upload>
        <div v-if="chamberOfCommerceDocument != null">
          {{ chamberOfCommerceDocument.fileName }}
        </div>
      </div>
    </div>

    <h6 class="mt-5">Company official address</h6>

    <div class="form-group required">
      <input
        type="text"
        name="firstname"
        class="form-control required"
        placeholder="Street"
        v-model="companyAddressStreet"
      />
    </div>

    <div class="row">
      <div class="col-6">
        <div class="form-group required">
          <input
            type="text"
            name="firstname"
            class="form-control required"
            placeholder="House number"
            v-model="companyAddressHouseNumber"
          />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <input
            type="text"
            name="firstname"
            class="form-control required"
            placeholder="Additional info"
            v-model="companyAddressOther"
          />
        </div>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <div class="form-group required">
          <input
            type="text"
            name="firstname"
            class="form-control required"
            placeholder="Postal code"
            v-model="companyAddressPostalCode"
          />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group required">
          <input
            type="text"
            name="firstname"
            class="form-control required"
            placeholder="City"
            v-model="companyAddressCity"
          />
        </div>
      </div>
    </div>

    <div class="form-group">
      <div class="styled-select clearfix">
        <select
          class="form-control required"
          name="country"
          v-model="companyAddressCountryCode"
        >
          <option :value="null" disabled>Country of Address</option>
          <option
            v-for="country of countries"
            :value="country.code"
            :key="country.code"
          >
            {{ country.name }}
          </option>
        </select>
      </div>
    </div>

    <div class="form-group">
      <h6 class="mt-5">
        Do you want to use different correspondence address?
        <a-popover title="">
          <template slot="content">
            <div>
              You can opt in to use different address for the correspondence
              purposes, such as mail delivery.
            </div>
          </template>
          <a
            ><font-awesome-icon class="text-primary" icon="question-circle"
          /></a>
        </a-popover>
      </h6>

      <div class="btn-group block" role="group">
        <a
          @click="() => (differentCorrespondenceAddress = true)"
          class="btn btn-sm border-warning"
          :class="{
            'btn-warning': differentCorrespondenceAddress,
          }"
          size="small"
          type="primary"
        >
          {{ "Yes" }}
        </a>

        <a
          @click="() => (differentCorrespondenceAddress = false)"
          class="btn btn-sm border-warning"
          size="small"
          type="primary"
          :class="{
            'btn-warning': differentCorrespondenceAddress === false,
          }"
        >
          {{ "No" }}
        </a>
      </div>
    </div>

    <div v-if="differentCorrespondenceAddress">
      <div class="form-group required">
        <input
          type="text"
          name="firstname"
          class="form-control required"
          placeholder="Street"
          v-model="correspondenceAddressStreet"
        />
      </div>

      <div class="row">
        <div class="col-6">
          <div class="form-group required">
            <input
              type="text"
              name="firstname"
              class="form-control required"
              placeholder="House number"
              v-model="correspondenceAddressHouseNumber"
            />
          </div>
        </div>
        <div class="col-6">
          <div class="form-group">
            <input
              type="text"
              name="firstname"
              class="form-control required"
              placeholder="Additional info"
              v-model="correspondenceAddressOther"
            />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col-6">
          <div class="form-group required">
            <input
              type="text"
              name="firstname"
              class="form-control required"
              placeholder="Postal code"
              v-model="correspondenceAddressPostalCode"
            />
          </div>
        </div>
        <div class="col-6">
          <div class="form-group required">
            <input
              type="text"
              name="firstname"
              class="form-control required"
              placeholder="City"
              v-model="correspondenceAddressCity"
            />
          </div>
        </div>
      </div>

      <div class="form-group required">
        <div class="styled-select clearfix">
          <select
            class="form-control required"
            name="country"
            v-model="correspondenceAddressCountryCode"
          >
            <option :value="null" disabled>Country of Address</option>
            <option
              v-for="country of countries"
              :value="country.code"
              :key="country.code"
            >
              {{ country.name }}
            </option>
          </select>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { createComputed } from "@/services/utilities";
import { API_URL } from "@/services/config";

export default {
  name: "Step2",
  data: function () {
    return {
      fileUploadApiUrl: API_URL + "wapi/ioss/account/document",
      uploadedFileList: [],
    };
  },
  methods: {
    fileUploadParams() {
      return {
        token: this.token,
      };
    },

    handleFileChange(info) {
      if (info.file.status !== "uploading") {
        this.uploadedFileList = info.fileList;
      }
      if (
        info.file.status === "done" &&
        info.file.response.status === "success"
      ) {
        this.chamberOfCommerceDocument = info.file.response.data.file;
        this.uploadedFileList = [];
      }
    },
  },
  computed: {
    companyLegalName: createComputed("form", "form.companyLegalName"),
    companyCorCode: createComputed("form", "form.companyCorCode"),
    companyAddressStreet: createComputed("form", "form.companyAddressStreet"),
    companyAddressHouseNumber: createComputed(
      "form",
      "form.companyAddressHouseNumber"
    ),
    companyAddressOther: createComputed("form", "form.companyAddressOther"),
    companyAddressPostalCode: createComputed(
      "form",
      "form.companyAddressPostalCode"
    ),
    companyAddressCity: createComputed("form", "form.companyAddressCity"),
    companyAddressCountryCode: createComputed(
      "form",
      "form.companyAddressCountryCode"
    ),
    differentCorrespondenceAddress: createComputed(
      "form",
      "form.differentCorrespondenceAddress"
    ),

    correspondenceAddressStreet: createComputed(
      "form",
      "form.correspondenceAddressStreet"
    ),
    correspondenceAddressHouseNumber: createComputed(
      "form",
      "form.correspondenceAddressHouseNumber"
    ),
    correspondenceAddressOther: createComputed(
      "form",
      "form.correspondenceAddressOther"
    ),
    correspondenceAddressPostalCode: createComputed(
      "form",
      "form.correspondenceAddressPostalCode"
    ),
    correspondenceAddressCity: createComputed(
      "form",
      "form.correspondenceAddressCity"
    ),
    correspondenceAddressCountryCode: createComputed(
      "form",
      "form.correspondenceAddressCountryCode"
    ),
    incorporationType: createComputed("form", "form.incorporationType"),
    incorporationRegistryType: createComputed(
      "form",
      "form.incorporationRegistryType"
    ),
    incorporationRegistryName: createComputed(
      "form",
      "form.incorporationRegistryName"
    ),
    chamberOfCommerceNumber: createComputed(
      "form",
      "form.chamberOfCommerceNumber"
    ),
    chamberOfCommerceDocument: createComputed(
      "form",
      "form.chamberOfCommerceDocument"
    ),

    ...mapGetters("system", ["countries"]),
    ...mapGetters("form", ["token"]),
  },
};
</script>

<style scoped>
.btn-group.block {
  display: flex;
}

.block .btn {
  flex: 1;
}
</style>
