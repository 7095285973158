<template>
  <div>
    <div class="form-group">
      <h6 class="mt-5">
        Are you currently registered for VAT OSS services in any member state?
        <!-- <a><font-awesome-icon class="text-primary" icon="question-circle" /></a> -->
      </h6>

      <div class="btn-group block" role="group">
        <a
          @click="enableCurrentRegistrations"
          class="btn btn-sm border-warning"
          :class="{
            'btn-warning': isIossRegistered,
          }"
          size="small"
          type="primary"
        >
          {{ "Yes" }}
        </a>

        <a
          @click="() => (isIossRegistered = false)"
          class="btn btn-sm border-warning"
          size="small"
          type="primary"
          :class="{
            'btn-warning': isIossRegistered === false,
          }"
        >
          {{ "No" }}
        </a>
      </div>
    </div>

    <div class="" v-if="isIossRegistered">
      <p>Please list all your existing registrations</p>

      <div>
        <div v-for="(registration, index) in iossRegistrations" :key="index">
          <div class="row">
            <div class="col-6">
              <div class="form-group required">
                <div class="styled-select clearfix">
                  <select
                    class="form-control required"
                    name="country"
                    @change="
                      (event) =>
                        setCurrentRegistrationProp(
                          index,
                          'countryCode',
                          event.target.value
                        )
                    "
                  >
                    <option :value="null">Country of registration</option>
                    <option
                      v-for="country of countries"
                      :selected="country.code == registration.countryCode"
                      :value="country.code"
                      :key="country.code"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="form-group required">
                <input
                  type="text"
                  name="firstname"
                  class="form-control required"
                  placeholder="OSS Number"
                  :value="registration.ossNumber"
                  @change="
                    (event) =>
                      setCurrentRegistrationProp(
                        index,
                        'ossNumber',
                        event.target.value
                      )
                  "
                />
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12 text-right">
              <a
                @click="removeOssRegistration(index)"
                class="btn btn-light btn-sm"
                size="small"
                type="primary"
              >
                <font-awesome-icon icon="trash-alt" class="text-danger" />
                {{ "Remove this registration" }}
              </a>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <a
              @click="addOssRegistration"
              class="btn btn-light btn-sm"
              size="small"
              type="primary"
            >
              <font-awesome-icon icon="plus-circle" class="text-primary" />
              {{ "Add more registrations" }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <div class="form-group">
      <h6 class="mt-5">
        Have you previously been registered for VAT OSS services in any member
        state?
        <!-- <a><font-awesome-icon class="text-primary" icon="question-circle" /></a> -->
      </h6>

      <div class="btn-group block" role="group">
        <a
          @click="enablePastRegistrations"
          class="btn btn-sm border-warning"
          :class="{
            'btn-warning': wasIossRegistered,
          }"
          size="small"
          type="primary"
        >
          {{ "Yes" }}
        </a>

        <a
          @click="() => (wasIossRegistered = false)"
          class="btn btn-sm border-warning"
          size="small"
          type="primary"
          :class="{
            'btn-warning': wasIossRegistered === false,
          }"
        >
          {{ "No" }}
        </a>
      </div>
    </div>

    <div class="" v-if="wasIossRegistered">
      <p>Please list all your existing registrations</p>

      <div>
        <div
          v-for="(registration, index) in pastIossRegistrations"
          :key="index"
        >
          <div class="row">
            <div class="col-6">
              <div class="form-group required">
                <div class="styled-select clearfix">
                  <select
                    class="form-control required"
                    name="country"
                    @change="
                      (event) =>
                        setPastRegistrationProp(
                          index,
                          'countryCode',
                          event.target.value
                        )
                    "
                  >
                    <option :value="null">Country of registration</option>
                    <option
                      v-for="country of countries"
                      :value="country.code"
                      :key="country.code"
                      :selected="country.code == registration.countryCode"
                    >
                      {{ country.name }}
                    </option>
                  </select>
                </div>
              </div>
            </div>

            <div class="col-6">
              <div class="form-group required">
                <input
                  type="text"
                  name="firstname"
                  class="form-control required"
                  placeholder="OSS Number"
                  :value="registration.ossNumber"
                  @change="
                    (event) =>
                      setPastRegistrationProp(
                        index,
                        'ossNumber',
                        event.target.value
                      )
                  "
                />
              </div>
            </div>
          </div>

          <div class="row mb-3">
            <div class="col-12 text-right">
              <a
                @click="removePastOssRegistration(index)"
                class="btn btn-light btn-sm"
                size="small"
                type="primary"
              >
                <font-awesome-icon icon="trash-alt" class="text-danger" />
                {{ "Remove this registration" }}
              </a>
            </div>
          </div>
        </div>

        <div class="row">
          <div class="col-12">
            <a
              @click="addPastOssRegistration"
              class="btn btn-light btn-sm"
              size="small"
              type="primary"
            >
              <font-awesome-icon icon="plus-circle" class="text-primary" />
              {{ "Add more registrations" }}
            </a>
          </div>
        </div>
      </div>
    </div>

    <h6 class="mt-5">
      Date of the first supply

      <a-popover title="">
        <template slot="content">
          <div>Date of the first supply cannot be before 01.07.2021.</div>
        </template>
        <a><font-awesome-icon class="text-primary" icon="question-circle" /></a>
      </a-popover>
    </h6>

    <div class="form-group required">
      <input
        type="email"
        name="email"
        class="form-control required"
        placeholder="Date of First Supply"
        v-model="dateOfFirstSupply"
      />

      <!-- <span class="font-italic font-weight-light"></span> -->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { createComputed } from "@/services/utilities";

export default {
  name: "Step5",
  data: function () {
    return {};
  },
  methods: {
    enableCurrentRegistrations() {
      this.isIossRegistered = true;
      if (!this.iossRegistrations.length) {
        this.addOssRegistration();
      }
    },
    addOssRegistration() {
      this.$store.dispatch("form/addFormArrayProperty", {
        prop: "iossRegistrations",
        data: {
          countryCode: null,
          ossNumber: "",
        },
      });
    },
    removeOssRegistration(index) {
      this.$store.dispatch("form/removeFormArrayProperty", {
        prop: "iossRegistrations",
        index,
      });
    },
    setCurrentRegistrationProp(index, objectProp, data) {
      this.$store.dispatch("form/setFormArrayObjectProperty", {
        prop: "iossRegistrations",
        index,
        objectProp,
        data,
      });
    },
    enablePastRegistrations() {
      this.wasIossRegistered = true;
      if (!this.pastIossRegistrations.length) {
        this.addPastOssRegistration();
      }
    },
    addPastOssRegistration() {
      this.$store.dispatch("form/addFormArrayProperty", {
        prop: "pastIossRegistrations",
        data: {
          countryCode: null,
          ossNumber: "",
        },
      });
    },
    removePastOssRegistration(index) {
      this.$store.dispatch("form/removeFormArrayProperty", {
        prop: "pastIossRegistrations",
        index,
      });
    },
    setPastRegistrationProp(index, objectProp, data) {
      this.$store.dispatch("form/setFormArrayObjectProperty", {
        prop: "pastIossRegistrations",
        index,
        objectProp,
        data,
      });
    },
  },
  computed: {
    ...mapGetters("system", ["countries"]),
    isIossRegistered: createComputed("form", "form.isIossRegistered"),
    iossRegistrations: createComputed("form", "form.iossRegistrations"),
    wasIossRegistered: createComputed("form", "form.wasIossRegistered"),
    pastIossRegistrations: createComputed("form", "form.pastIossRegistrations"),
    dateOfFirstSupply: createComputed("form", "form.dateOfFirstSupply"),
  },
};
</script>

<style scoped>
.btn-group.block {
  display: flex;
}

.block .btn {
  flex: 1;
}
</style>
