var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"form-group"},[_c('h6',{staticClass:"mt-5"},[_vm._v(" Are you currently registered for VAT OSS services in any member state? ")]),_c('div',{staticClass:"btn-group block",attrs:{"role":"group"}},[_c('a',{staticClass:"btn btn-sm border-warning",class:{
          'btn-warning': _vm.isIossRegistered,
        },attrs:{"size":"small","type":"primary"},on:{"click":_vm.enableCurrentRegistrations}},[_vm._v(" "+_vm._s("Yes")+" ")]),_c('a',{staticClass:"btn btn-sm border-warning",class:{
          'btn-warning': _vm.isIossRegistered === false,
        },attrs:{"size":"small","type":"primary"},on:{"click":function () { return (_vm.isIossRegistered = false); }}},[_vm._v(" "+_vm._s("No")+" ")])])]),(_vm.isIossRegistered)?_c('div',{},[_c('p',[_vm._v("Please list all your existing registrations")]),_c('div',[_vm._l((_vm.iossRegistrations),function(registration,index){return _c('div',{key:index},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group required"},[_c('div',{staticClass:"styled-select clearfix"},[_c('select',{staticClass:"form-control required",attrs:{"name":"country"},on:{"change":function (event) { return _vm.setCurrentRegistrationProp(
                        index,
                        'countryCode',
                        event.target.value
                      ); }}},[_c('option',{domProps:{"value":null}},[_vm._v("Country of registration")]),_vm._l((_vm.countries),function(country){return _c('option',{key:country.code,domProps:{"selected":country.code == registration.countryCode,"value":country.code}},[_vm._v(" "+_vm._s(country.name)+" ")])})],2)])])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group required"},[_c('input',{staticClass:"form-control required",attrs:{"type":"text","name":"firstname","placeholder":"OSS Number"},domProps:{"value":registration.ossNumber},on:{"change":function (event) { return _vm.setCurrentRegistrationProp(
                      index,
                      'ossNumber',
                      event.target.value
                    ); }}})])])]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-12 text-right"},[_c('a',{staticClass:"btn btn-light btn-sm",attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.removeOssRegistration(index)}}},[_c('font-awesome-icon',{staticClass:"text-danger",attrs:{"icon":"trash-alt"}}),_vm._v(" "+_vm._s("Remove this registration")+" ")],1)])])])}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('a',{staticClass:"btn btn-light btn-sm",attrs:{"size":"small","type":"primary"},on:{"click":_vm.addOssRegistration}},[_c('font-awesome-icon',{staticClass:"text-primary",attrs:{"icon":"plus-circle"}}),_vm._v(" "+_vm._s("Add more registrations")+" ")],1)])])],2)]):_vm._e(),_c('div',{staticClass:"form-group"},[_c('h6',{staticClass:"mt-5"},[_vm._v(" Have you previously been registered for VAT OSS services in any member state? ")]),_c('div',{staticClass:"btn-group block",attrs:{"role":"group"}},[_c('a',{staticClass:"btn btn-sm border-warning",class:{
          'btn-warning': _vm.wasIossRegistered,
        },attrs:{"size":"small","type":"primary"},on:{"click":_vm.enablePastRegistrations}},[_vm._v(" "+_vm._s("Yes")+" ")]),_c('a',{staticClass:"btn btn-sm border-warning",class:{
          'btn-warning': _vm.wasIossRegistered === false,
        },attrs:{"size":"small","type":"primary"},on:{"click":function () { return (_vm.wasIossRegistered = false); }}},[_vm._v(" "+_vm._s("No")+" ")])])]),(_vm.wasIossRegistered)?_c('div',{},[_c('p',[_vm._v("Please list all your existing registrations")]),_c('div',[_vm._l((_vm.pastIossRegistrations),function(registration,index){return _c('div',{key:index},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group required"},[_c('div',{staticClass:"styled-select clearfix"},[_c('select',{staticClass:"form-control required",attrs:{"name":"country"},on:{"change":function (event) { return _vm.setPastRegistrationProp(
                        index,
                        'countryCode',
                        event.target.value
                      ); }}},[_c('option',{domProps:{"value":null}},[_vm._v("Country of registration")]),_vm._l((_vm.countries),function(country){return _c('option',{key:country.code,domProps:{"value":country.code,"selected":country.code == registration.countryCode}},[_vm._v(" "+_vm._s(country.name)+" ")])})],2)])])]),_c('div',{staticClass:"col-6"},[_c('div',{staticClass:"form-group required"},[_c('input',{staticClass:"form-control required",attrs:{"type":"text","name":"firstname","placeholder":"OSS Number"},domProps:{"value":registration.ossNumber},on:{"change":function (event) { return _vm.setPastRegistrationProp(
                      index,
                      'ossNumber',
                      event.target.value
                    ); }}})])])]),_c('div',{staticClass:"row mb-3"},[_c('div',{staticClass:"col-12 text-right"},[_c('a',{staticClass:"btn btn-light btn-sm",attrs:{"size":"small","type":"primary"},on:{"click":function($event){return _vm.removePastOssRegistration(index)}}},[_c('font-awesome-icon',{staticClass:"text-danger",attrs:{"icon":"trash-alt"}}),_vm._v(" "+_vm._s("Remove this registration")+" ")],1)])])])}),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('a',{staticClass:"btn btn-light btn-sm",attrs:{"size":"small","type":"primary"},on:{"click":_vm.addPastOssRegistration}},[_c('font-awesome-icon',{staticClass:"text-primary",attrs:{"icon":"plus-circle"}}),_vm._v(" "+_vm._s("Add more registrations")+" ")],1)])])],2)]):_vm._e(),_c('h6',{staticClass:"mt-5"},[_vm._v(" Date of the first supply "),_c('a-popover',{attrs:{"title":""}},[_c('template',{slot:"content"},[_c('div',[_vm._v("Date of the first supply cannot be before 01.07.2021.")])]),_c('a',[_c('font-awesome-icon',{staticClass:"text-primary",attrs:{"icon":"question-circle"}})],1)],2)],1),_c('div',{staticClass:"form-group required"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.dateOfFirstSupply),expression:"dateOfFirstSupply"}],staticClass:"form-control required",attrs:{"type":"email","name":"email","placeholder":"Date of First Supply"},domProps:{"value":(_vm.dateOfFirstSupply)},on:{"input":function($event){if($event.target.composing){ return; }_vm.dateOfFirstSupply=$event.target.value}}})])])}
var staticRenderFns = []

export { render, staticRenderFns }