
export const createComputed = (storeModule, field) => {
  return {
    get() {
      console.log("vuex: computing " + field);

      if (field.indexOf(".") > -1) {
        let obj = this.$store.state[storeModule];
        let segments = field.split(".");
        for (let part of segments) {
          obj = obj[part];
        }
        console.log(obj);
        return obj;
      } else {
        let x = this.$store.state[storeModule][field];
        console.log(x);
        return x;
      }
    },
    set(val) {
      let obj = {};
      let segments = field.split(".");
      obj[segments.pop()] = val;
      while (segments.length > 0) {
        obj = { [segments.pop()]: obj };
      }
      this.$store.dispatch(`${storeModule}/set`, obj);
    },
  };
};

export const emailIsValid = (email) => {
  return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email)
}

export const phoneIsValid = (phone) => {
  return /^\+?\d+$/.test(phone)
}
