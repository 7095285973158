<template>
  <div>
    <div class="form-group required">
      <input
        type="text"
        name="firstname"
        class="form-control required"
        placeholder="First Name"
        v-model="firstName"
        id="firstFormField"
      />
    </div>
    <div class="form-group required">
      <input
        type="text"
        name="lastname"
        class="form-control required"
        placeholder="Last Name"
        v-model="lastName"
      />
    </div>
    <div class="form-group required">
      <input
        type="email"
        name="email"
        class="form-control required"
        placeholder="Your Email"
        v-model="userEmail"
      />
    </div>
    <div class="form-group required">
      <input
        type="text"
        name="telephone"
        class="form-control"
        placeholder="Telephone"
        v-model="userPhone"
      />
    </div>
    <div class="form-group required">
      <input
        type="text"
        name="jobTitle"
        class="form-control"
        placeholder="Your capacity/Job title"
        v-model="userJobTitle"
      />
    </div>
    <div class="form-group">
      <input
        type="text"
        name="refCode"
        class="form-control"
        placeholder="Your reference and/or service code (if applicable)"
        v-model="serviceReferenceCode"
      />
    </div>
    <div class="form-group">

      <p class="mt-3 font-weight-light">We will automatically save your data after each step. To make this
        registration process easy for you, the information is automatically
        saved. You can continue registering using the link sent to you after you
        click the button below.
      </p>
    </div>
  </div>
</template>

<script>
import { createComputed } from "@/services/utilities";

// import { mapState } from 'vuex';
export default {
  name: "Step1",
  computed: {
    firstName: createComputed("form", "form.firstName"),
    lastName: createComputed("form", "form.lastName"),
    userEmail: createComputed("form", "form.userEmail"),
    userPhone: createComputed("form", "form.userPhone"),
    serviceReferenceCode: createComputed("form", "form.serviceReferenceCode"),
    userJobTitle: createComputed("form", "form.userJobTitle"),
  },
};
</script>
