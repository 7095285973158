<template>
  <div class="about">
    <OnboardingForm/>
  </div>
</template>

<script>

import OnboardingForm from '@/components/OnboardingForm.vue'

export default {
  name: "Register",
  components: {
    OnboardingForm
  }
}
</script>
